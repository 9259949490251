import { gql } from "@apollo/client";

export const getEventsPageQuery = gql`
    query {
        eventsPage {
            id
            imagenEncabezado {
                url
                provider
            }
            descripcion
            tiposEventos
            serviciosAdicionales
            galeriaTradicionales {
                url
                provider
            }
            galeriaIgualitarias {
                url
                provider
            }
            galeriaQuince {
                url
                provider
            }
            linkCotizacionWhatsapp
        }
    }
`;