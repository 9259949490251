import { 
    Get_Layout, 
    Get_Layout_Success, 
    Get_Events_Page,
    Get_Events_Page_Success,
    Get_Habitaciones,
    Get_Habitaciones_Success,
    Get_Tipos_Habitacion,
    Get_Tipos_Habitacion_Success,
    Get_Rutas,
    Get_Rutas_Success,
    On_Error, 
    Get_Hotel_Page,
    Get_Hotel_Page_Success,
    Get_Restaurant_Page,
    Get_Restaurant_Page_Success,
    Get_Spa_Page_Success,
    Get_Spa_Page
} from "./layout.actiontypes";
import { LayoutState } from '../../../../core/models/state/libertg/layout.state.model';
import { IAction } from '../../../../core/models/state/action.model';

const State: LayoutState = {
    layout: null,
    hotelPage: null,
    restaurantPage: null,
    spaPage: null,
    eventsPage: null,
    habitaciones: [],
    tiposHabitacion: [],
    rutas: [],
    loading: false,
    error: null
};

// eslint-disable-next-line
export default (state: LayoutState = State, { type, payload }: IAction) => {
    switch (type) {
        case Get_Layout:            
        case Get_Hotel_Page:
        case Get_Restaurant_Page:
        case Get_Spa_Page:
        case Get_Events_Page:
        case Get_Habitaciones:
        case Get_Tipos_Habitacion:
        case Get_Rutas:
            return {
                ...state,
                loading: true
            };    
        case Get_Layout_Success:            
            return {
                ...state,
                loading: false,
                layout: payload,
                error: null
            };
        case Get_Hotel_Page_Success:
            return {
                ...state,
                loading: false,
                hotelPage: payload,
                error: null
            };
        case Get_Restaurant_Page_Success:
            return {
                ...state,
                loading: false,
                restaurantPage: payload,
                error: null
            };
        case Get_Spa_Page_Success:
            return {
                ...state,
                loading: false,
                spaPage: payload,
                error: null
            };
        case Get_Events_Page_Success:
            return {
                ...state,
                loading: false,
                eventsPage: payload,
                error: null
            };
        case Get_Habitaciones_Success:
            return {
                ...state,
                loading: false,
                habitaciones: payload,
                error: null
            };
        case Get_Tipos_Habitacion_Success:
            return {
                ...state,
                loading: false,
                tiposHabitacion: payload,
                error: null
            };
        case Get_Rutas_Success:
            return {
                ...state,
                loading: false,
                rutas: payload,
                error: null
            };
        case On_Error:            
            return {
                ...state,
                loading: false,
                error: payload
            };
        default:
            return state;
    }
}