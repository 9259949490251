import styled from "@emotion/styled";
import { NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export const CustomNavDropdown = styled(NavDropdown)`
  white-space: nowrap;
  color: rgb(0, 71, 55) !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1rem;
`;

export const CustomNavLink = styled(NavLink)`
  white-space: nowrap;
  color: rgb(0, 71, 55) !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1rem;
`;

export const CustomReservaButton = styled.button`
  background-color: rgb(110, 69, 0) !important;
  color: white;
  white-space: nowrap;
  font-size: 1rem;
`;

export const CustomContacto = styled.a`
  color: rgb(0, 71, 55);
  text-decoration: none;
`;

export const CustomRed = styled.a`
  color: rgb(0, 71, 55);
  text-decoration: none;
  padding-right: 1rem;
`;

export const CustomDocumentLink = styled.a`
  color: rgb(0, 71, 55);
`;

export const CustomBrand = styled.div`
  display: flex;
  flex-direction: column;
`;
