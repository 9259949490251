import { Dispatch } from "redux";
import { 
    getLayout,
    getEventsPage,
    getHabitaciones,
    getTiposHabitacion,
    getRutas,
    getHotelPage,
    getRestaurantPage,
    getSpaPage
} from "../services/layout.service";
import { 
    GetLayout, 
    GetLayoutSuccess, 
    GetEventsPage,
    GetEventsPageSuccess,
    GetHabitaciones,
    GetHabitacionesSuccess,
    GetTiposHabitacion,
    GetTiposHabitacionSuccess,
    GetRutas,
    GetRutasSuccess,
    OnError,
    GetHotelPage,
    GetHotelPageSuccess,
    GetRestaurantPageSuccess,
    GetRestaurantPage,
    GetSpaPage,
    GetSpaPageSuccess
} from './layout.actions'

export const getLayout$ = () => async (dispatch: Dispatch) => {
    dispatch( GetLayout() );
    try {            
        dispatch( GetLayoutSuccess( await getLayout() ) );
    } catch (error: any) {
        dispatch( OnError( error ) );
    }
};

export const getHotelPage$ = () => async (dispatch: Dispatch) => {
    dispatch(GetHotelPage());
    try {            
        dispatch( GetHotelPageSuccess( await getHotelPage() ) );
    } catch (error: any) {
        dispatch( OnError( error ) );
    }
}

export const getResturantPage$ = () => async (dispatch: Dispatch) => {
    dispatch(GetRestaurantPage());
    try {            
        dispatch( GetRestaurantPageSuccess( await getRestaurantPage() ) );
    } catch (error: any) {
        dispatch( OnError( error ) );
    }
}

export const getSpaPage$ = () => async (dispatch: Dispatch) => {
    dispatch(GetSpaPage());
    try {            
        dispatch( GetSpaPageSuccess( await getSpaPage() ) );
    } catch (error: any) {
        dispatch( OnError( error ) );
    }
}

export const getEventsPage$ = () => async (dispatch: Dispatch) => {
    dispatch(GetEventsPage());
    try {
        dispatch(GetEventsPageSuccess( await getEventsPage() ));
    }
    catch (error: any) {
        dispatch( OnError( error ) );
    }
}

export const getHabitaciones$ = () => async (dispatch: Dispatch) => {
    dispatch(GetHabitaciones());
    try {            
        dispatch( GetHabitacionesSuccess( await getHabitaciones() ) );
    } catch (error: any) {
        dispatch( OnError( error ) );
    }
};

export const getTiposHabitacion$ = () => async (dispatch: Dispatch) => {
    dispatch(GetTiposHabitacion());
    try {            
        dispatch( GetTiposHabitacionSuccess( await getTiposHabitacion() ) );
    } catch (error: any) {
        dispatch( OnError( error ) );
    }
};

export const getRutas$ = () => async (dispatch: Dispatch) => {
    dispatch(GetRutas());
    try {            
        dispatch( GetRutasSuccess( await getRutas() ) );
    } catch (error: any) {
        dispatch( OnError( error ) );
    }
};