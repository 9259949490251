import client from '../../../../core/graphqlClient'
import { getLayoutQuery } from "../../../../core/graphql/layout.query";
import { getEventsPageQuery } from '../../../../core/graphql/eventspage.query';
import { getHabitacionesQuery } from '../../../../core/graphql/habitaciones.query';
import { getTiposHabiticionQuery } from '../../../../core/graphql/tiposhabitacion.query';
import { getRutasQuery } from '../../../../core/graphql/rutas.query';
import { LayoutModel } from "../../../../core/models/base/libertg/layout.model";
import { AboutPageModel } from '../../../../core/models/base/libertg/aboutpage.model';
import { EventsPageModel } from '../../../../core/models/base/libertg/eventspage.model';
import { HabitacionModel } from '../../../../core/models/base/libertg/habitacion.model';
import { TipoHabitacionModel } from '../../../../core/models/base/libertg/tipohabitacion.model';
import { RutaModel } from '../../../../core/models/base/libertg/ruta.model';
import { HotelPageModel } from '../../../../core/models/base/libertg/hotelpage.model';
import { getHotelPageQuery } from '../../../../core/graphql/hotelpage.query';
import { getRestuarantPageQuery } from '../../../../core/graphql/restuarantpage.query';
import { RestaurantPageModel } from '../../../../core/models/base/libertg/restaurantpage.model';
import { SpaPageModel } from '../../../../core/models/base/libertg/spapage.model';
import { getSpaPageQuery } from '../../../../core/graphql/spapage.query';

export const getLayout = async (): Promise<LayoutModel> => {
    const { data: { layout } } = await client.query<{ layout: LayoutModel }>({
        query: getLayoutQuery
    });
    return layout;
};

export const getHotelPage = async (): Promise<HotelPageModel> => {
    const { data: { hotelPage } } = await client.query<{ hotelPage: HotelPageModel }>({
        query: getHotelPageQuery
    });
    return hotelPage;
};

export const getRestaurantPage = async (): Promise<RestaurantPageModel> => {
    const { data: { restaurantPage } } = await client.query<{ restaurantPage: RestaurantPageModel }>({
        query: getRestuarantPageQuery
    });
    return restaurantPage;
};

export const getSpaPage = async (): Promise<SpaPageModel> => {
    const { data: { spaPage } } = await client.query<{ spaPage: SpaPageModel }>({
        query: getSpaPageQuery
    });
    return spaPage;
};

export const getEventsPage = async (): Promise<EventsPageModel> => {
    const { data: { eventsPage } } = await client.query<{ eventsPage: EventsPageModel }>({
        query: getEventsPageQuery
    });
    return eventsPage;
};

export const getHabitaciones = async (): Promise<HabitacionModel[]> => {
    const { data: { habitaciones } } = await client.query<{ habitaciones: HabitacionModel[] }>({
        query: getHabitacionesQuery
    });
    return habitaciones;
};

export const getTiposHabitacion = async (): Promise<TipoHabitacionModel[]> => {
    const { data: { tiposHabitacions } } = await client.query<{ tiposHabitacions: TipoHabitacionModel[] }>({
        query: getTiposHabiticionQuery
    });
    return tiposHabitacions;
};

export const getRutas = async (): Promise<RutaModel[]> => {
    const { data: { rutas } } = await client.query<{ rutas: RutaModel[] }>({
        query: getRutasQuery
    });
    return rutas;
}