import { FC } from "react";
import { Carousel } from 'react-bootstrap';
import { CustomCarouselImage } from "./style";
import { getFileUrl } from "../../../../core/utils/file.util";

type CarouselComponentProps = {
    Carrousel: {
        url: string;
        provider: string;
        mime: string;
    }[];
}

const CarouselComponent: FC<CarouselComponentProps> = ({ Carrousel }) => {



    return(
        <>
            <Carousel style={{ paddingTop: '5.5rem' }}>
                {
                    Carrousel.map((cr, i) => (
                        <Carousel.Item key={i}>
                            { 
                                cr && cr.mime && cr.mime.includes('image') 
                                ? <CustomCarouselImage src={getFileUrl(cr.url, cr.provider)}/>
                                : <video autoPlay muted loop src={getFileUrl(cr.url, cr.provider)} style={{ width: '100%', height: 'auto' }}></video>
                            }                            
                        </Carousel.Item>
                    ))
                }
            </Carousel>
        </>
    )    
};

export default CarouselComponent;