import { FC } from "react"
import { RestaurantPageModel } from "../../../../../core/models/base/libertg/restaurantpage.model"
import CarouselComponent from "../../../shared/components/carousel"
import TitleDescriptionComponent from "../../../shared/components/titleDescription"
import GalleryComponent from "../../../shared/components/gallery"

type RestaurantPageProps = {
    restaurantPage: RestaurantPageModel
}

const RestaurantPage: FC<RestaurantPageProps> = ({ restaurantPage: { Carousel, Titulo, Descripcion, Gallery } }) => {
    return (
        <>
            <CarouselComponent Carrousel={Carousel}></CarouselComponent>
            <TitleDescriptionComponent Titulo={Titulo} Descripcion={Descripcion}></TitleDescriptionComponent>
            <GalleryComponent Gallery={Gallery}></GalleryComponent>
        </>
    );
}

export default RestaurantPage;