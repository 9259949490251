import { Dispatch, FC, SetStateAction } from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Image } from 'react-bootstrap';
import { getFileUrl } from '../../../../core/utils/file.util';

type ModalGalleryProps = {
    show: boolean;
    index: number;
    img: {
        id: string;
        url: string;
        provider: string;
    };
    galeria: {
        id: string;
        url: string;
        provider: string;
    }[];
    setShow: Dispatch<SetStateAction<boolean>>;
    setIndex: Dispatch<SetStateAction<number>>;
    setImg: Dispatch<SetStateAction<{
        id: string;
        url: string;
        provider: string;
    }>>;
}

const ModalGallery: FC<ModalGalleryProps> = ({ show, index, img, galeria, setShow, setIndex, setImg }) => {

    const moveImage = (step: number) => {
        if (galeria.length -1 === index && step === 1) {
            setIndex(0);
            setImg(galeria[0]);
        } else if (index === 0 && step === -1) {
            setIndex(galeria.length -1);
            setImg(galeria[galeria.length -1]);
        } else {
            setIndex(index + step);
            setImg(galeria[index]);
        }
    };

    return (
        <Modal 
            size="lg"
            show={show} 
            onHide={() => setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <button type="button" className="btn d-block mr-0 ml-auto" onClick={() => setShow(false)}>×</button>
            </Modal.Header>
            <Modal.Body>
                <Image 
                    className="col-md-12" 
                    src={getFileUrl(img.url, img.provider)} 
                    style={{
                        width:'100%', 
                        height:'100%'
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary float-left" onClick={() => moveImage(-1)}>
                    <FontAwesomeIcon icon={ faArrowLeft }/>
                </button>
                <button type="button" className="btn btn-secondary float-right" onClick={() => moveImage(1)}>
                    <FontAwesomeIcon icon={ faArrowRight }/>
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalGallery;