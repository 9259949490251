import { gql } from "@apollo/client";

export const getSpaPageQuery = gql`
    query {
        spaPage {
            id
            Carousel {
                url
                provider
                mime
            }
            Titulo
            Descripcion
            Gallery {
                id
                url
                provider
                mime
            }
        }
    }
`;