import { gql } from "@apollo/client";

export const getTiposHabiticionQuery = gql`
    query {
        tiposHabitacions {
            id
            tipo
            titulo
        }
    }
`;