import { gql } from "@apollo/client";

export const getHabitacionesQuery = gql`
  query {
    habitaciones {
      id
      imagenes {
        url
        provider
      }
      numeroHabitacion
      ubicacion
      tipohabitacion {
        id
        tipo
        titulo
      }
      comodidades
      idHabitacion
    }
  }
`;
