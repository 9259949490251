export const Get_Layout = 'Get Layout';
export const Get_Layout_Success = 'Get Layout Success';
export const Get_Hotel_Page = 'Get Hotel Page';
export const Get_Hotel_Page_Success = 'Get Hotel Page Success';
export const Get_Restaurant_Page = 'Get Restaurant Page';
export const Get_Restaurant_Page_Success = 'Get Restaurant Page Success';
export const Get_Spa_Page = 'Get Spa Page';
export const Get_Spa_Page_Success = 'Get Spa Page Success';
export const Get_Events_Page = 'Get Events Page';
export const Get_Events_Page_Success = 'Get Events Page Success';
export const Get_Habitaciones = 'Get Habitaciones';
export const Get_Habitaciones_Success = 'Get Habitaciones Success';
export const Get_Tipos_Habitacion = 'Get Tipos_Habitacion';
export const Get_Tipos_Habitacion_Success = 'Get Tipos_Habitacion Success';
export const Get_Rutas = 'Get Rutas';
export const Get_Rutas_Success = 'Get Rutas Success';
export const On_Error = 'Error';