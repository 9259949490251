import { FC } from 'react';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { LayoutModel } from '../../../../core/models/base/libertg/layout.model';
import { getFileUrl } from '../../../../core/utils/file.util';
import { CustomContacto, CustomRed, CustomDocumentLink } from './style';

type FooterProps = {
    layout: LayoutModel
} 

const Footer: FC<FooterProps> = ({ layout: { rnt, tituloContactos, contactos, redes, documentos } }) => {
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDzXLUAmyRHfzUzyo5zq0FB3nPprq_Eyis"
    });

    return (
        <footer className="pt-3 pb-5">
            <Container>
                <Row>
                    <Col md={4} className="align-self-center">
                        <Row className="pb-1">
                            <Col sm={12} className="pb-1">
                                <h1 style={{ color: 'rgb(0, 71, 55)' }}>{tituloContactos}</h1>
                            </Col>
                            {
                                contactos.map(contacto => (
                                    <Col key={contacto.id} sm={12} className="pb-1">
                                        <h5 className="text-light">
                                            <CustomContacto href={ contacto.value }>
                                                { 
                                                    contacto.tipo === 'telefono' ? 
                                                        (<FontAwesomeIcon icon={ faPhoneAlt } />)
                                                    : contacto.tipo === 'mail' ?
                                                        (<FontAwesomeIcon icon={ faEnvelope } />)
                                                    : null
                                                }
                                                { contacto.contacto }
                                            </CustomContacto>
                                        </h5>
                                    </Col>
                                ))
                            }
                            <Col sm={12} className="pb-1">
                                {
                                    isLoaded ? (

                                        <GoogleMap
                                            mapContainerStyle={{
                                                width: '100%',
                                                height: '200px'
                                            }}
                                            center={{
                                                lat: 6.3857425,
                                                lng: -75.4883536,
                                            }}
                                            zoom={12}
                                        >
                                            <Marker position={{
                                                lat: 6.3857425,
                                                lng: -75.4883536,
                                            }} />
                                        </GoogleMap>
                                    ) : <></>
                                }
                            </Col>
                            <Col sm={12} className="pb-1">
                                <h6 style={{ color: 'rgb(0, 71, 55)' }}>Vereda el Zarzal - Copacabana, Antioquia, Colombia </h6>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} className="align-self-center pt-3">
                        <Row>
                            <Col sm={12} className="pb-3">
                                {
                                    redes.map(red => (
                                        <CustomRed key={red.id} href={red.link} target="_blank" rel="noopener noreferrer" className="pr-2">
                                            { 
                                                red.red === 'facebook' ? 
                                                    (<FontAwesomeIcon icon={ faFacebook } size="3x"/>)
                                                : red.red === 'instagram' ?
                                                    (<FontAwesomeIcon icon={ faInstagram } size="3x"/>)
                                                : red.red === 'youtube' ?
                                                    (<FontAwesomeIcon icon={ faYoutube } size="3x"/>)
                                                : null
                                            }
                                        </CustomRed>
                                    ))
                                }
                            </Col>
                        </Row>
                        <Row>
                            {
                                documentos.map(doc => (
                                    <Col key={doc.id} sm={12} className="pb-2">
                                        <CustomDocumentLink href={getFileUrl(doc.archivo.url, doc.archivo.provider)} target="_blank" rel="noopener noreferrer">{doc.nombre}</CustomDocumentLink>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                    <Col md={4} className="pt-5">
                        <Card>
                            <Card.Header>
                                <h2 className="text-dark text-center">Danos tu opinión</h2>
                            </Card.Header>
                            <Card.Body>
                                <a href="https://g.page/libertg-hotels/review?gm" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-block">
                                    <FontAwesomeIcon icon={ faGoogle } style={{ paddingRight: '4px' }}/>
                                    Clic aquí y calificanos.
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;