import { FC, useState } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { CustomColThumb } from "./style";
import ImgGallery from "./imgGallery";
import ModalGallery from "./modalGallery";

type GalleryComponentProps = {
    Gallery: {
        id: string;
        url: string;
        provider: string;
        mime: string;
    }[];
}
const GalleryComponent: FC<GalleryComponentProps> = ({ Gallery }) => {

    const [show, setShow] = useState(false);
    const [img, setImg] = useState({
        id: '',
        url: '',
        provider: ''
    });
    const [index, setIndex] = useState(0);

    const showModalGalery = (gal: { id: string; url: string; provider: string }, i: number) => {
        setImg(gal);
        setIndex(i);
        setShow(true);
    };

    return (
        <div>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h2 className="font-weight-bold" style={{ color: 'rgb(0, 71, 55)' }}>GALERIA</h2>
                    </Col>
                    <Col sm={12}>
                        <Row>
                            {
                                Gallery.map((gal, i) => (
                                    <CustomColThumb
                                        key={gal.id}
                                        lg={3}
                                        onClick={ () => showModalGalery(gal, i) }
                                    >
                                        <ImgGallery
                                            img={gal} 
                                            index={i}
                                        />
                                    </CustomColThumb>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ModalGallery
                show={show} 
                index={index}
                img={img}
                galeria={Gallery} 
                setShow={setShow} 
                setIndex={setIndex}
                setImg={setImg}
            />
        </div>
    )
};

export default GalleryComponent;