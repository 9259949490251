import { FC } from 'react';

const Loading: FC = () => {
    return (
        <div className="container-loading">
            <h1>Bienvenido a Libertg Hotels - Spa</h1>
            <h2>Espere Por favor</h2>
            <div className="bird-container bird-container--one">
                <div className="bird bird--one"></div>
            </div>
            <div className="bird-container bird-container--two">
                <div className="bird bird--two"></div>
            </div>
            <div className="bird-container bird-container--three">
                <div className="bird bird--three"></div>
            </div>
            <div className="bird-container bird-container--four">
                <div className="bird bird--four"></div>
            </div>
        </div>
    );
};

export default Loading;