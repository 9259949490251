import styled from '@emotion/styled';
import { Row, Col, Image } from 'react-bootstrap';

export const CustomCarouselImage = styled(Image)`
    display: block;
    margin: auto;
    width: 100%;
    height: auto;
`;

export const CustomServiceRow = styled(Row)`
    cursor: pointer;
`;

export const CustomServiceCol = styled(Col)`
    color: white; 
    text-align: center;
`;

export const CustomServiceImage = styled.div`
    img {
        width: 100%;
        height: auto;
        border-radius: 5%;
    }
`;

export const CustomColThumb = styled(Col)`
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const CustomImageGallery = styled(Image)`
    width: 100%;
    border-radius: 5%;
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
`;

export const CustomImageModalGallery = styled(Image)`
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
`;