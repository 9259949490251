import { gql } from "@apollo/client";

export const getRutasQuery = gql`
    query {
        rutas {
            id
            pagina
            ruta
            componente
            esDropdown
            posicion
            tipohabitacion {
                id
                tipo
                titulo
            }
        }
    }
`;