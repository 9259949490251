import { FC } from "react";
import { Provider } from "react-redux";
import Layout from "./layout/container/layout";
import store from "./store";
import "./libertg.scss";

interface BookingParams {
  RoomID: number;
}

declare global {
  interface Window {
    Booking: {
      Open: (params?: BookingParams) => void;
    };
  }
}

const App: FC = () => {
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
};

export default App;
