import { FC } from "react";
import { Navbar, Nav, Container, Image } from "react-bootstrap";
import { LayoutModel } from "../../../../core/models/base/libertg/layout.model";
import { getFileUrl } from "../../../../core/utils/file.util";
import {
  CustomNavLink,
  CustomNavDropdown,
  CustomReservaButton,
  CustomBrand,
} from "./style";

type HeaderProps = {
  layout: LayoutModel;
};

const Header: FC<HeaderProps> = ({
  layout: { rnt, logoEncabezado, rutas, linkReserva },
}) => {
  rutas = [...rutas];

  const handleClick = () => {
    window.Booking.Open();
    return false;
  };
  return (
    <>
      <header>
        <Navbar
          expand="lg"
          className="navbar navbar-expand-lg navbar-light bg-light fixed-top"
        >
          <Container className="container-fluid">
            <Navbar.Brand href="/" style={{ display: "contents" }}>
              <CustomBrand>
                <Image
                  src={getFileUrl(logoEncabezado.url, logoEncabezado.provider)}
                  width="100px"
                  height="auto"
                />
                <h6 style={{ color: "rgb(0, 71, 55)" }}>{rnt}</h6>
              </CustomBrand>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="ml-auto float-end"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto float-end">
                {rutas
                  .sort((a, b) => a.posicion - b.posicion)
                  .map((r) => {
                    if (r.esDropdown) {
                      return (
                        <CustomNavDropdown
                          key={r.id}
                          title={r.pagina}
                          className="font-weight-bold"
                          id="basic-nav-dropdown"
                        >
                          {r.rutas.map((sr) => (
                            <CustomNavLink
                              data-rr-ui-dropdown-item
                              key={sr.id}
                              to={sr.ruta}
                              className="dropdown-item"
                            >
                              {sr.pagina}
                            </CustomNavLink>
                          ))}
                        </CustomNavDropdown>
                      );
                    } else {
                      return (
                        <Nav.Item key={r.id}>
                          <CustomNavLink
                            to={r.ruta}
                            className="nav-link font-weight-bold"
                          >
                            {r.pagina}
                          </CustomNavLink>
                        </Nav.Item>
                      );
                    }
                  })}
                <Nav.Item>
                  <CustomReservaButton
                    type="button"
                    className="btn"
                    onClick={handleClick}
                  >
                    Reserva Aquí
                  </CustomReservaButton>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
            <br />
            <div className="be-panel"></div>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
