import { gql } from "@apollo/client";

export const getLayoutQuery = gql`
    query {
        layout {
            id
            linkReserva
            bodyBackground {
                url
                provider
            }
            galeriaBackground {
                url
                provider
            }
            logoEncabezado {
                url
                provider
            }
            logoPie {
                url
                provider
            }
            rnt
            rutas {
                id
                pagina
                ruta
                esDropdown
                componente
                rutas {
                    id
                    pagina
                    ruta
                    componente
                    tipohabitacion {
                        id
                        tipo
                        titulo
                    }
                }
                posicion
            }
            tituloContactos
            contactos {
                id
                contacto
                icono
                tipo
                value
            }
            redes {
                id
                red
                icono
                link
            }
            documentos {
                id
                nombre
                archivo {
                    url
                    provider
                }
            }
        }
    }
`;