export enum UrlAPIs {
    // DEV
    // GetReviews = 'https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJuyCOHro7RI4R6WyErVRgtMw&key=AIzaSyDzXLUAmyRHfzUzyo5zq0FB3nPprq_Eyis',
    // StrapiGraphql = 'http://localhost:1337/graphql',
    // Strapi = 'http://localhost:1337',
    // Logo = '/uploads/logo_Verde_bc106c8abd.png',
    // PRD
    GetReviews = 'https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJuyCOHro7RI4R6WyErVRgtMw&key=AIzaSyDzXLUAmyRHfzUzyo5zq0FB3nPprq_Eyis',
    StrapiGraphql = 'https://cms-libertg.herokuapp.com/graphql',
    Strapi = 'https://cms-libertg.herokuapp.com',
    Logo = '/uploads/logo_Verde_bc106c8abd.png',
}