import { FC } from "react";
import { SpaPageModel } from "../../../../../core/models/base/libertg/spapage.model";
import CarouselComponent from "../../../shared/components/carousel";
import GalleryComponent from "../../../shared/components/gallery";
import TitleDescriptionComponent from "../../../shared/components/titleDescription";

type SpaPageProps = {
    spaPage: SpaPageModel;
};

const SpaPage: FC<SpaPageProps> = ({ spaPage: { Carousel, Titulo, Descripcion, Gallery } }) => {
    return(
        <>
        <CarouselComponent Carrousel={Carousel}></CarouselComponent>
        <TitleDescriptionComponent Titulo={Titulo} Descripcion={Descripcion}></TitleDescriptionComponent>
        <GalleryComponent Gallery={Gallery}></GalleryComponent>
    </>
    );
};

export default SpaPage;